<template>
  <body
    class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <!-- Modal -->
      <div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed">
          <div class="kt-header-mobile__logo">
              <a href="demo3/index.html">
                  <img alt="Logo" src="../../assets/media/site-logo.png" />
              </a>
          </div>
          <div class="kt-header-mobile__toolbar">
              <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
                  id="kt_aside_mobile_toggler">
                  <span></span>
              </button>
              <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
                  <span></span>
              </button>
          </div>
      </div>
    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <SideBar ref="modal" />
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper responsiv__space" id="kt_wrapper"
          style="padding-top: 60px !important;">
          <div id="kt_content" style="
              padding-top: 0;
              padding-bottom: 0;
    margin: 17px;
              overflow: hidden;
              position: relative;
              justify-content: center;
            ">
            <div class="top-title resp-top-spacing mb-4">
              <h1 class="text-white" style="
    font-weight: bold;">Settings</h1>

              <span v-on:click="BackToDashboard()" class="btn btn-brand fw-normal text-white"
                style="background: #b60808; border: #b60808;    margin-left: 10px; cursor: pointer;">
                Back
              </span>
            </div>
            <!-- begin:: Content -->
            <h2 class="text-white" style="margin-bottom: 15px;
    font-weight: bold;">Open room settings:</h2>
            <div class="webinar-recordings-list">
              <div style="display: flex;align-items: center;margin-bottom: 15px">


                <h4 class="text-white" style="
    font-weight: bold;margin: 0;">Keep room always open </h4>


                <span style="background: #878787;
    border: 1px solid black;
    color:black;margin-left: 5px;
    border-radius: 61px;cursor: pointer;
    padding: 0px 8px;" class="myDIV">i</span>
                <span class="tooltiptext hide" v-if="this.roomOpen">No one will be allowed to enter the room
                  unless
                  a host enters the room and unlocks it. Before this, people will be placed in a waiting room until
                  the room is unlocked, then they will be let in. </span>
                <span class="tooltiptext hide" v-if="this.roomOpen == false">Keeping the room open is nice because no one
                  has to be there to allow people in the room. Anyone at anytime can enter your room. The downside to
                  this is, if people show up and a member of your team is not there, anything can be said in the room
                  without you being there. Be cautious when leaving a room open as anyone with the link will freely be
                  able to enter the room. </span>

                <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px;    border: 2px solid #707070;
    border-radius: 9px;background: #26303F;text-align: center">
                  <button type="button" class="btn btn-blue" style="    
    border-radius: 6px;
    padding: 5px 25px;    margin: 4px;
    font-size: 17px;    margin-right: 0px;
    border: none;" :style="this.roomOpen == false ? 'background: #2d8cff' : ''" v-on:click="alwaysOpenRoom(false)">
                    Yes
                  </button>
                  <button type="button" class="btn btn-blue" :style="this.roomOpen == true ? 'background: #2d8cff' : ''"
                    style="    
    border-radius: 6px;
    padding: 5px 25px;margin: 4px;
    font-size: 17px;
    border: none;" v-on:click="alwaysOpenRoom(true)">
                    No
                  </button>
                </div>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px">
                <h4 class="text-white" style="
    font-weight: bold;    margin: 0;">Who can lock/unlock room? </h4>

                <span style="background: #878787;
    border: 1px solid black;
    color:black;margin-left: 5px;
    border-radius: 61px;cursor: pointer;
    padding: 0px 8px;" class="myDIV">i</span>
                <span class="tooltiptext hide">Users who can lock/unlock a room can also allow people in from the waiting
                  room. </span>


                <div class="form-group" style="margin: 0;margin-left: 10px;display: flex;">
                  <label class="container" style="margin: 0;color: white;
    font-weight: 500;
    font-size: 18px;">Hosts
                    <input type="checkbox" v-if="hostUnlockRoom == 'Yes'" checked="checked"
                      @click="hostUnlockRoomAction('No')">
                    <input type="checkbox" v-if="hostUnlockRoom == 'No'" @click="hostUnlockRoomAction('Yes')">
                    <span class="checkmark"></span>
                  </label>
                  <label class="container" style="margin: 0;color: white;
    font-weight: 500;
    font-size: 18px;">Moderators
                    <input type="checkbox" v-if="moderatorsUnlockRoom == 'Yes'" checked="checked"
                      @click="moderatorsUnlockRoomAction('No')">
                    <input type="checkbox" v-if="moderatorsUnlockRoom == 'No'" @click="moderatorsUnlockRoomAction('Yes')">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div style="display: flex;align-items: center;flex-wrap: wrap;margin-bottom: 15px;">
                <h4 class="text-white" style="
    font-weight: bold;    margin: 0;">Require pin to lock/unlock room : </h4>
                <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px;    border: 2px solid #707070;
    border-radius: 9px;background: #26303F;text-align: center;">
                  <button type="button" class="btn btn-blue" style="    
    border-radius: 6px;
    padding: 5px 25px;    margin: 4px;
    font-size: 17px;    margin-right: 0px;
    border: none;" :style="this.isRequirePin == 'Yes' ? 'background: #2d8cff' : ''"
                    v-on:click="isRequirePinUpdate('Yes')">
                    Yes
                  </button>
                  <button type="button" class="btn btn-blue"
                    :style="this.isRequirePin == 'No' ? 'background: #2d8cff' : ''" style="    
    border-radius: 6px;
    padding: 5px 25px;    margin: 4px;
    font-size: 17px;
    border: none;" v-on:click="isRequirePinUpdate('No')">
                    No
                  </button>
                </div>

                <div v-if="this.isRequirePin == 'Yes'" class="form-group" style="margin-bottom: 0px !important; margin-left: 10px;cursor: pointer;    border: 2px solid #2d8cff;
    border-radius: 9px;background: #26303F;    text-align: center;    display: flex;
    align-items: center;">

                  <input style="border-radius: 6px;
    color: white;
    font-weight: bold;width: 7rem;    letter-spacing: 5px;background: #26303f;    padding: 0px 12px;
    font-size: 17px;
    border: none;" type="text" class="form-control" placeholder="PIN" @input="cleanVariable" maxlength="4" v-model="lockUnlockPin" />
                  <img @click="copyPin()" style="    margin-bottom: 2px;
    margin-right: 10px;;" alt="Logo" height="25" width="25" src="../../assets/media/copyIcon.png" />
                </div>

                <div v-if="this.isRequirePin == 'Yes'" class="form-group" style="margin-bottom: 0px !important; margin-left: 10px;cursor: pointer;     text-align: center;   border: 2px solid #2d8cff;
    border-radius: 9px;" @click="generateRandomPin()">
                  <span type="button" class="" style=" 
    border-radius: 6px;    color: #2d8cff;
    font-weight: bold;
    padding: 5px 10px;    margin: 4px;
    font-size: 17px;    margin-right: 0px;
    border: none;">
                    Save Pin
                  </span>
                </div>

              </div>
              <h3 class="text-white " style="margin-bottom: 15px;display:none">Configure what happens when users join:
              </h3>
              <div style="margin-left: 30px; display:none">
                <div style="display: flex;align-items: center;" class="mb-2">
                  <h3 class="text-white mb-0">Hosts:</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.hostRequied" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('hostRequied', this.hostRequied)">
                      <option value="Password required">Password required</option>
                      <option value="No Password required" selected>
                        No Password required
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2" style="display: flex;align-items: center;">
                  <h3 class="text-white">=> Hosts can unlock open room?</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.hostUnlockRoom" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('hostUnlockRoom', this.hostUnlockRoom)">
                      <option value="Yes">Yes</option>
                      <option value="No" selected>
                        No
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2" style="display: flex;align-items: center;">
                  <h3 class="text-white mb-0">Moderators:</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.moderatorsRequied" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('moderatorsRequied', this.moderatorsRequied)">
                      <option value="Password required">Password required</option>
                      <option value="No Password required" selected>
                        No Password required
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2" style="display: flex;align-items: center;">
                  <h3 class="text-white ">=> Moderators can unlock open room?</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.moderatorsUnlockRoom" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('moderatorsUnlockRoom', this.moderatorsUnlockRoom)">
                      <option value="Yes">Yes</option>
                      <option value="No" selected>
                        No
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2" style="display: flex;align-items: center;">
                  <h3 class="text-white mb-0">Guests:</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.guestsRequied" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('guestsRequied', this.guestsRequied)">
                      <option value="Password required">Password required</option>
                      <option value="No Password required" selected>
                        No Password required
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-2" style="display: flex;align-items: center;">
                  <h3 class="text-white mb-0">Viewers:</h3>
                  <div class="form-group" style="margin-bottom: 0px !important; margin-left: 10px">
                    <select v-model="this.viewersRequied" style="height: auto; padding: 0"
                      class="blue-border dark-bg form-control" placeholder="Time Zone"
                      v-on:change="updateRequiedApi('viewersRequied', this.viewersRequied)">
                      <option value="Password required">Password required</option>
                      <option value="No Password required" selected>
                        No Password required
                      </option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">

              <div class="webinar-recordings-list">
                <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">

                </div>
              </div>
            </div>

            <!-- end:: Content -->
          </div>

        </div>


        <div class="modal fade bd-example-modal-xl" id="warningModal" tabindex="-1" role="dialog" aria-hidden="true"
          style="background: #000000bf;">
          <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content" style="background: #fff;">
              <div class="border-dotted">
                <div class="modal-body">
                  <div class="row" style="margin-top: 14px;">
                    <div class="col-md-12">
                      <h3 class="text-dark weight-700">
                        Are you sure you want to Delete Recordings?
                      </h3>
                      <hr style="margin-bottom: 0;" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 text-center align-items-center justify-content-center" style="margin-bottom: 15px;">
                <span v-on:click="FinalDeleteRecords()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                  style="width: 150px; background: #b60808; color: #fff;position: relative" data-toggle="modal"
                  data-target="#add-new-webinar-calendar">
                  Yes
                  <span v-if="is_delete_loader" class="spinner spinner-white spinner-right"></span>
                </span>
                <span v-on:click="closewarningModal()" type="button" id="BtnNotReadableError" class="btn white-btn mr-3"
                  style="width: 150px; background: green; color: #fff;" data-toggle="modal"
                  data-target="#add-new-webinar-calendar">
                  No
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
window.addEventListener("pageshow", function (event) {
  var historyTraversal = event.persisted,
    perf = window.performance,
    perfEntries = perf && perf.getEntriesByType && perf.getEntriesByType("navigation"),
    perfEntryType = perfEntries && perfEntries[0] && perfEntries[0].type,
    navigationType = perf && perf.navigation && perf.navigation.type;
  if (historyTraversal || perfEntryType === "back_forward" || navigationType === 2) {
    // Handle page restore.
    window.location.reload(true);
  }
});

import { ref, reactive } from "vue";
import axios from "axios";
import {
  getRecording,
  downloadRecording,
  deleteRecording,
  lockUnlockOpenRoom,
  lastwebinarlist,
  updateOpenRoomSetting,
} from "../services/service";
import io from "socket.io-client";
import SideBar from "../WebinarModel/SideBar.vue";

axios.defaults.headers.post["Content-Type"] = "application/json";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { v4 as uuidv4 } from "uuid";
export default {
  components: {
    SideBar,
  },
  data() {
    Vue.nextTick(() => {
      document.title = "Unity Webinar";
    });
    return {
      chunks: ref([]),
      record: [],
      is_delete_loader: false,
      isAllChecked: false,
      deleteIds: [],
      downloadLoader: [],
      webinarId: '',
      roomOpen: false,
      lockUnlockPin: '0000',
      namea: '',
      moderatorsUnlockRoom: 'No',
      hostRequied: '',
      moderatorsRequied: '',
      isRequirePin: '',
      guestsRequied: '',
      roomId: '',
      viewersRequied: '',
      hostUnlockRoom: 'No',
      progress: 5,
      data_loader: true,
      date: new Date(),
      time: new Date().toLocaleString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      socket: null,
      BASE_URL:
        window.location.host == "unitywebinar.com" ? "https://api.unitywebinar.com/" : window.location.host == "dev.unitywebinar.com" ? "https://dev-api.unitywebinar.com/" : "https://api.unitywebinar.com/",
    };
  },
  computed: {
  },
  mounted: async function () {

  },

  async created() {

    $(function () {
      $(".toggleP").on("click", function () {
        if ($(this).hasClass("on")) {
          $("#lockModal").modal("hide");
          $("#unlockModal").modal("show");
          // $(this).removeClass('on');
        } else {
          $("#unlockModal").modal("hide");
          $("#lockModal").modal("show");
          // $(this).addClass('on');
        }
      });
    });
    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/login");
    } else {
      this.socket = io(this.BASE_URL, { autoConnect: true });

      await this.loadData();
    }

  },

  methods: {
    async cleanVariable(event) {
      this.lockUnlockPin = event.target.value.replace(/[^0-9]/g, "");
   
    },
    async copyPin() {
      var copyText = this.lockUnlockPin;

      navigator.clipboard.writeText(copyText).then(function () {

        alert('copied to clipboard')

      }, function () {

        alert('copied to clipboard failed')

      });
    },
    async generateRandomPin() {

      // this.lockUnlockPin = Math.floor(1000 + Math.random() * 9000);
      const $toast = useToast();
      if (this.lockUnlockPin) {
        const body = {
          is_edit: 1,
          lockRoomPin: this.lockUnlockPin,
        }
        const data = await updateOpenRoomSetting(body);
        console.log('data-------', data.data.result.data);
        let instance = $toast.success('Pin generated successfully.');
      } else {
        let instance = $toast.error('Please enter a PIN.');
      }
    },
    async BackToDashboard() {
      this.$router.push("/calendar");
    },
    async moderatorsUnlockRoomAction(x) {

      const body = {
        is_edit: 1,
        moderatorsUnlockRoom: x,
      }
      const data = await updateOpenRoomSetting(body);
      console.log('data-isRequirePin------', data.data.result.data);
      const $toast = useToast();
      this.moderatorsUnlockRoom = x;

      if (x == 'Yes') {
        let instance = $toast.success('Moderator can able to lock/unlock the room.');
      } else {
        let instance = $toast.error('Moderator can\'t able to lock/unlock the room.');
      }

    },
    async hostUnlockRoomAction(x) {
      console.log("x---------", x);
      console.log("hostUnlockRoom---------", this.hostUnlockRoom);

      const body = {
        is_edit: 1,
        hostUnlockRoom: x,
      }
      const data = await updateOpenRoomSetting(body);
      console.log('data-isRequirePin------', data.data.result.data);
      const $toast = useToast();
      this.hostUnlockRoom = x;
      if (x == 'Yes') {
        let instance = $toast.success('Hosts can able to lock/unlock the room.');
      } else {
        let instance = $toast.error('Hosts can\'t able to lock/unlock the room.');
      }

    },
    async isRequirePinUpdate(x) {

      const body = {
        is_edit: 1,
        isRequirePin: x,
      }
      const data = await updateOpenRoomSetting(body);
      console.log('data-isRequirePin------', data.data.result.data);
      const $toast = useToast();
      this.isRequirePin = x;

      if (x == 'Yes') {
        let instance = $toast.success('Pin is required for lock/unlock room.');
      } else {
        let instance = $toast.error('Pin is not required for lock/unlock room.');
      }

    },
    async alwaysOpenRoom(x) {

      var body = {
        webinarId: this.webinarId,
        lockRoom: x,
      };
      console.log("body-D--ds-", $("#txt_name").val());

      console.log("body----", body);
      const data = await lockUnlockOpenRoom(body);
      console.log(x)


      const $toast = useToast();
      this.roomOpen = x;
      console.log(this.roomOpen)
      if (x == true) {
        this.socket.emit("unlockLockFlag", { room: this.roomId, status: 1 });
        let instance = $toast.error('Room is no longer always open.');
      } else {
        this.socket.emit("roomUnlockNotify", { room: this.roomId });
        this.socket.emit("unlockLockFlag", { room: this.roomId, status: 0 });
        let instance = $toast.success('Room is always open.');
      }

    },
    async updateRequiedApi(key, value) {
      const obj = {
        "is_edit": 1,
        "age": value
      };

      const newKeys = {
        "is_edit": 'is_edit',
        "age": key
      };

      const body = {};

      Object.keys(obj).forEach(key => {
        body[newKeys[key]] = obj[key];
      });

      console.log(body);

      const data = await updateOpenRoomSetting(body);
      const $toast = useToast();
      let instance = $toast.success('Setting updated!');
    },
    async loadData() {
      const data = await updateOpenRoomSetting();
      const lastwebinar = await lastwebinarlist();

      if (lastwebinar.data != null) {
        this.webinarId = lastwebinar.data != null ? lastwebinar.data.result.data._id : '';
        this.roomOpen = lastwebinar.data.result.data.lockRoom;
        var roomId1 = JSON.parse(localStorage.getItem("userdetails")).companyname;
        var roomId = roomId1 + lastwebinar.data.result.data.title;
        console.log('roomId-------', roomId);
        this.roomId = roomId;
        this.socket.emit("addUserSetting", roomId);

        this.socket.on("unlockLockFlag", (data) => {

          if (data.status == 0) {
            this.roomOpen = false;
          } else {
            this.roomOpen = true;

          }
          console.log('unlockLockFlag-----------', data)
        });
        console.log('roomId-------', this.socket);
      } else {
        this.webinarId = lastwebinar.data != null ? lastwebinar.data.result.data.lockRoom : false;
        this.roomOpen = lastwebinar.data.result.data.lockRoom;
      }

      console.log('lastwebinar---------', lastwebinar.data.result.data._id)
      console.log('lastwebinar---------', lastwebinar.data.result.data.lockRoom)
      console.log('lastwebinar-----s----', this.webinarId);
      console.log('data------------', data);
      if (data.status == 200) {
        this.moderatorsUnlockRoom = data.data.result.data.moderatorsUnlockRoom || 'No';
        this.hostRequied = data.data.result.data.hostRequied || '';
        this.moderatorsRequied = data.data.result.data.moderatorsRequied || '';
        this.guestsRequied = data.data.result.data.guestsRequied || '';
        this.viewersRequied = data.data.result.data.viewersRequied || '';
        this.hostUnlockRoom = data.data.result.data.hostUnlockRoom || 'No';
        this.lockUnlockPin = data.data.result.data.lockRoomPin || '0000';
        this.isRequirePin = data.data.result.data.isRequirePin || '';
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style scoped>
/* Absolute Center Spinner */
tr:first-child {
  background: transparent;
  margin: 0 !important;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 15px !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 1em !important;
}

td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr {
  background: #373737;
}

td {
  vertical-align: middle !important;
}

.kt-checkbox>input {
  position: relative !important;
}

::-webkit-scrollbar {
  width: 10px;
  margin-right: 10px !important;
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgb(45 140 255);
  background-clip: padding-box;
  border-radius: 9999px;
}

.kt-container {
  padding: 10px 10px !important;
}

.kt-checkbox.kt-checkbox--brand>span:after {
  border-color: white !important;
}

.dspinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 60%;
  right: 28%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

.recordAnimation {
  animation: record 1.5s ease infinite normal;
}

@keyframes record {
  10% {
    opacity: 0.1;
  }

  30% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}


.shell {
  height: 20px;
  width: 250px;
  border: 1px solid #aaa;
  border-radius: 13px;
  padding: 3px;
}

.bar {
  background: linear-gradient(to right, #B993D6, #8CA6DB);
  height: 20px;
  width: 15px;
  border-radius: 9px;

  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em
  }
}

button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}

.tooltip {
  position: relative;
  opacity: 3;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.hide {
  display: none;
}

.tooltiptext {
  width: 27rem;
  background-color: #26303f;
  color: #fff;
  border-radius: 20px;
  padding: 24px;
  position: absolute;
  z-index: 999999;
  right: 0px;
}

.myDIV:hover+.hide {
  display: block;
}

/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */


/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #2d8cff;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #101826;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>